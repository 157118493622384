import { render, staticRenderFns } from "./PublishDynamic.vue?vue&type=template&id=1201dac1&scoped=true&"
import script from "./PublishDynamic.vue?vue&type=script&lang=js&"
export * from "./PublishDynamic.vue?vue&type=script&lang=js&"
import style0 from "./PublishDynamic.vue?vue&type=style&index=0&id=1201dac1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1201dac1",
  null
  
)

export default component.exports