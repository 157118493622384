<template>
    <div class="publish-dynamic" @click="btnClick">
        <div class="add-icon">
            <img src="./img/add-icon.png" />
        </div>
        <div class="cell-rt">
            <div class="title">发布宠物动态</div>
            <p>爱心人士非常关心宠物的近况，<br/>您的每次更新都会带来更多帮助</p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'PublishDynamic',
    data() {
        return {};
    },
    computed: {
        ...mapState(['projuuid']),
    },
    methods: {
        btnClick() {
            this.$router.push({
                path: '/fund/project/updateDynamic',
                query: { projuuid: this.projuuid }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.publish-dynamic {
    position: relative;
    background: #F7F7F7;
    border-radius: 4px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    padding: 16px;

    .add-icon {
        width: 62px;
        height: 62px;
        border-radius: 4px;
        border: 1px dotted #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-right: 12px;

        img {
            width: 20px;
            height: auto;
        }
    }

    .cell-rt {
         .title {
            padding-left: 24px;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #333333;
            position: relative;
            text-align: left;
            margin-bottom: 10px;
            line-height: 1;
        }

        .title::before {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            background: url('./img/edit-icon.png') no-repeat center;
            background-size: cover;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: -1px;
        }

        p {
            font-size: 12px;
            color: #333333;
        }
    }

}
</style>
